@use 'src/src/app/scss/abstracts' as *;

.language-switcher {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  @include reset-text-indents;

  display: inline-block;
  position: relative;
  z-index: $z-index-dropdown;

  &__body {
    position: absolute;
    z-index: $z-index-dropdown;
    top: 100%;
    right: 0;
    min-width: 84px;
    height: 0;
    margin: 0;
    margin-top: 10px;
    overflow: hidden;
    transform: translateY(10px);
    transition:
      opacity 0.15s ease,
      transform 0.15s ease;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 4px 34px 0 rgb(118 69 255 / 50%);
    border-radius: $border-radius-s;

    @include mq($until: lg) {
      min-width: 58px;
    }
  }

  &__body-inner {
    position: relative;

    // max-height: 75vmin;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    font-size: 0;
    line-height: 0;
    border-radius: $border-radius-s;
    overflow: hidden;
    background-color: $color-semantic-main;
  }

  &__list {
    @include unlist;
  }

  &__list-item {
  }

  &__list-link {
    display: block;
    align-items: center;
    color: rgba($color-semantic-third, 0.85);
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-m;
    line-height: 120%;
    letter-spacing: -0.48px;
    text-decoration: none;
    text-transform: capitalize;
    padding: 7px;
    padding-left: 12px;
    padding-right: 12px;
    transition:
      background-color $transition-fast,
      color $transition-fast;

    @include mq($until: lg) {
      font-size: 19.2px;
      letter-spacing: -0.384px;
    }

    &:hover {
      color: $color-base-white-100;
      background: $color-semantic-secondary;
    }

    &:active {
      background: darken($color: $color-base-purple-500, $amount: 5%);
    }
  }

  &__toggler {
    @include reset-btn;

    display: inline-flex;
    align-items: center;
    width: 84px;
    height: 47px;
    transition: all $transition-fast;
    color: rgba($color-semantic-third, 0.85);
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-m;
    line-height: 120%;
    letter-spacing: -0.48px;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: $border-radius-2xs;

    @include mq($until: lg) {
      width: 58px;
      height: 37px;
      font-size: 19.2px;
      letter-spacing: -0.384px;
      padding-left: 7px;
      padding-right: 7px;
    }

    &:hover {
      background: $color-base-purple-900;
      color: $color-semantic-third;
    }

    &:active {
      color: rgba($color-semantic-third, 0.9);
      background-color: $color-semantic-main;
    }
  }

  &__toggler-text {
    text-transform: capitalize;
  }

  &__toggler-icon {
    flex: 0 0 auto;
    margin-left: 10px;
    margin-top: 4px;

    @include mq($until: lg) {
      width: 10px;
      height: 8px;
    }
  }

  &--is-open {
    display: inline-block;
    position: relative;

    #{$block-name} {
      &__toggler {
      }

      &__body {
        height: auto;
        visibility: visible;
        overflow: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
